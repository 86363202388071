function getProgramViewProgress(program, position,completed)
{
	var percent = 0;
	if (program.runtime != null)
	{
		var _runtime = program.runtime;
		percent = position / (_runtime.toString().indexOf(':') !== -1 ? getProgramDuration(_runtime) : _runtime);
	}
	return completed == 1 ? 1 : Math.min(Math.round(parseInt(percent * 100, 10)) / 100, 1);
}

function getProgramDuration(runtime)
{
	var dur = 0;
	if (runtime.indexOf(":") > -1)
	{
		dur = parseInt(runtime.split(":")[0], 10) * 60 + parseInt(runtime.split(":")[1], 10);
	}
	else
	{
		dur = parseInt(runtime * 60, 10);
	}
	return dur;
}

function getProgramsOfPs(idArr, type, callbackName, callback)
{
	var q = '"' + idArr.join('" "') + '"';
	var query = encodeURIComponent((type === 'program' ? type + '_id' : type === 'category' ? 'catId' : 'program_id') + ':(' + q + ')');
	var searchProgramFuncName = callbackName + 'Callback';
	var solrLocale = window.isDefaultContentLocale ? "" : "&locale=" + window.locale;
	var rows = idArr.length;
	var url = (type === 'program' ? SOLR_PROGRAM : type === 'category' ? SOLR_CATEGORY : SOLR_PROGRAM) + window.SOLR_HANDLER + '?q=' + query + solrLocale + '&rows=' + rows + '&wt=json&indent=true&fl=*&&json.wrf=' + searchProgramFuncName;
	NEU.util.getJSONPFeed(url, searchProgramFuncName, function (data)
	{
		callback && callback(data.response.docs);
	});
}

function getViewHistoryOfProgram(callbackName, callback, options)
{
	ps.viewHistory.list(null, {
		type: "program",
		ps: (options && options.ps) || 50,
		pn: (options && options.pn) || 1
	}, function (data)
	{
		var retProcess = {
			list: []
		};
		var list = data.contents.map(function (item)
		{
			return item.id;
		})

		if (list.length == 0)
		{
			retProcess = $.extend(true, {}, retProcess, {});
			callback && callback(retProcess);
			return;
		}
		getProgramsOfPs(list, 'program', callbackName + 'viewHistoryofProgram', function (_list)
		{
			retProcess.list = _list.map(function (item)
			{
				var program = data.contents.filter(function (_item)
				{
					return _item.id == item.pid;
				})[0];
				var duration = getProgramViewProgress(item, program.position, program.completed);
				return {
					id: item.pid,
					seoname: item.seoName,
					image: LOC_CONTENT + item.image,
					name: item.name,
					purchaseType: 'purchase-type-' + (item.purchaseTypeId ? item.purchaseTypeId : 0),
					dur: duration == 0 ? duration : (duration * 100 + '%'),
					duration: item.runtime,
					position: program.position,
					completed: program.completed,
					tvRating: item.tvRating
				}
			}).sort(function (a, b)
			{
				return findIndexFromArrayById(list, a.id) - findIndexFromArrayById(list, b.id)
			})
			retProcess = $.extend(true, {}, retProcess, {});
			callback && callback(retProcess);
		});
		function findIndexFromArrayById(array, id)
		{
			return array.indexOf(id.toString());
		}
	});
}

function getWatchList(callbackName, callback, options)
{
	var retProcess = {
		list: []
	};
	ps.favorite.list(null, {
		type: 'category',
		ps: (options && options.ps) || 50,
		pn: (options && options.pn) || 1
	}, function (data)
	{
		var list = data.contents.map(function (item)
		{
			return item.id;
		})

		if (list.length == 0)
		{
			retProcess = $.extend(true, {}, retProcess, options || {});
			callback && callback(retProcess);
			return;
		}
		getProgramsOfPs(list, 'category', callbackName + 'category', function (_list)
		{
			retProcess.list = _list.map(function (item)
			{
				return {
					id: item.catId,
					image: LOC_CONTENT + 'categories/' + item.catId + '_es.jpg',
					name: item.name,
					programLink: LOC_SERVER + 'category/' + item.seoName,
					programTag: item.style ? LOC_RESOURCE + SITE_ID + '/site_4/images/' + item.style.toLowerCase() + '_eb.png' : '',
					status:item.status
				}
			}).filter(function (_category) {
				return _category.status == 2 ? true : false;
			}).sort(function (a, b) {
				return list.indexOf(a.id.toString()) - list.indexOf(b.id.toString());
			})
			retProcess = $.extend(true, {}, retProcess, options || {});
			callback && callback(retProcess);
		});
	})
}
