var watchlistScroller = null;
var resumeScroller = null;
var regionsScroller = null;
var windowInnerHight = null;
var checkMobileTimerId = null;
var currentPurchase = null;
var currentMVPD = {};// {"status":"authorized","isLoggedIn":true,"data":{"id":"4707164d-e66f-4dec-8085-3e511b2b088a","mvpdId":"Charter_Direct","resources":["univision","galavision","deportes","fusion"]}}
var isLatestMVPD = false;
var isTemporaryUser = false;
$(document).ready(function () {
	if (isTVE && neuap) {	// 1. get current userStatus
		currentMVPD = neuap.getUserStatusFromCache();//Fixme: render UI with inital status
		currentMVPD.isLoggedIn && userMVPDLogoHandle(currentMVPD.data.mvpdId);

		// init enablePs
		userPsHandle(currentMVPD);

		// 2. update real userStatus
		neuap.onUserStatusChanged(function (data) {
			isLatestMVPD = true;
			$('body').addClass('isLatestMVPD')
			currentMVPD = data.user;
			data.changed && userLoggedInHandle(data.user);
			data.changed && userPsHandle(data.user);
			document.dispatchEvent(new CustomEvent('mvpd.userStatusChecked'));
			//GA
			ga('set', 'dimension1', userType);
			if (userType === 'mvpdsubscriber') {
				ga('set', 'dimension29', 'authenticated');
			} else if (userType === 'anonymous') {
				if (window.isFreeSampleUser == 'true' || localStorage.getItem('duration_expired')) {
					ga('set', 'dimension29', 'true');
				} else {
					ga('set', 'dimension29', 'false');
				}
			}
			//QOS
			if (data.changed) {
				if (userType === 'anonymous' && window.isFreeSampleUser) {
					$('body').addClass('isFreePreview');
					NLQosTracker && NLQosTracker.reInit({freePreview: 1});
				} else {
					$('body').removeClass('isFreePreview');
					NLQosTracker && NLQosTracker.reInit({freePreview: 0});
				}
			}
		});

		//IE compatible Event
		function CustomEvent(event, params) {
			params = params || {bubbles: false, cancelable: false, detail: null};
			var evt = document.createEvent('CustomEvent');
			evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
			return evt;
		}

		function userLoggedInHandle(currentUser) {
			// Handle isLoggedIn
			if (currentUser.isLoggedIn) {
				$('body').removeClass('isAnonymous').addClass('isMVPDLoggedIn');
				window.isMVPDLoggedIn = true;
				var provider = currentUser.data.mvpdId;
				$('.header__myaccount-mvpd-logout img.mvpd-icon-pc').attr('src', LOC_CONTENT + 'mvpds/' + provider + '_pc_2.png');
				$('.header__myaccount-mvpd-logout img.mvpd-icon-mobile').attr('src', LOC_CONTENT + 'mvpds/' + provider + '_ws_1.png');
				$('.header__right img.header__mvpdlogo').attr('src', LOC_CONTENT + 'mvpds/' + provider + '_pc_3.png');
				userMVPDLogoHandle(provider);
				if (currentUser.status === 'authorized') {
					var resourceIds = currentUser.data.resources;
					for (var i in resourceIds) {
						$('body').addClass('tve-auth-' + resourceIds[i]);
						if ($.inArray('tve-auth-' + resourceIds[i], userAuth) < 0) {
							userAuth.push('tve-auth-' + resourceIds[i]);
						}
					}
				}
			} else {
				$('body').removeClass('isMVPDLoggedIn').addClass('isAnonymous');
				$('.header__myaccount-mvpd-logout img.mvpd-icon-pc').attr('src', '');
				$('.header__myaccount-mvpd-logout img.mvpd-icon-mobile').attr('src', '');
				$('.header__right img.header__mvpdlogo').attr('src', '');
				window.isMVPDLoggedIn = false;
				userAuth = [];
			}
		}

		function userPsHandle(currentUser) {
			// Handle ps
			if (currentUser.status == 'authorized') {
				window.enablePs = true;
				$('body').addClass('enablePs');
			} else {
				window.enablePs = false;
				$('body').removeClass('enablePs');
			}
		}

		function userMVPDLogoHandle(provider) {
			var url = '';
			if (g_mvpdsConfig != null) {
				for (var i = 0; i < g_mvpdsConfig.mvpds.length; i++) {
					if (g_mvpdsConfig.mvpds[i].id == provider && g_mvpdsConfig.mvpds[i].status != false) {
						url = g_mvpdsConfig.mvpds[i].links ? g_mvpdsConfig.mvpds[i].links.pc : '';
					}
				}
			}


			if (url) {
				$('.header__right .header__mvpdlogo-link').attr('href', url).removeClass('disabled');
			} else {
				$('.header__right .header__mvpdlogo-link').attr('href', '').addClass('disabled');
			}
		}
	}

	var previousMVPD = neuap.getUserStatusFromCache();
	if (!isTVE && previousMVPD.isLoggedIn && new Date(TEMPRORARY_USER.expiryDate).getTime() >= NEU.parseDate(currentServerDate).getTime()) {
		isTemporaryUser = true;
		$('body').addClass('isTemporaryUser');
	}

	//Provicy Policy Update Notice
	showUpdateNoticePopup();

	if (!isTVE && (!isSubscriber)) {
		window.DTC_iNTERSITITIAL_SCREEN && showAccountTemporaryPopup();
	}

	//Check need to deeplink
	handlePlayDeepLink();

	//play with channel/video param
	if (window.channelParamSeoName) {
		var channelParamSeoNameLowerCase = window.channelParamSeoName.toLowerCase();
		var currentRegion = ((window.isVIP && NEU.util.getCookie('chooseRegion')) || contentRegions[0].name).toLowerCase();
		waitFinalStatus(function () {
			getChannels && getChannels(function (data) {
				(data || []).forEach(function (group) {
					(group.list || []).forEach(function (channel) {
						var seoname = channel.seoname.toLowerCase();

						if (seoname.indexOf(currentRegion) > -1) {
							var channelSeoname = seoname.substring(0, seoname.indexOf(currentRegion));
						}
						if ((seoname === channelParamSeoNameLowerCase) || (channelSeoname && (channelSeoname === channelParamSeoNameLowerCase))) {
							playChannel(seoname);
						}
					})
				})
			})
		});
	} else if (window.videoParamSeoName) {
		waitFinalStatus(function () {
			var type = pageName == 'category' ? 'show' : null;
			playProgram(window.videoParamSeoName.toLowerCase(), type, null);
		});
	}


	// Firefox reload page need make sure iframe src is null
	$('#signInOptionsFrame').attr('src', '');

	$.popupDismissEverywhere();
	//PARAM: embed=true
	//for mobile app, remove header & footer
	var param = window.location.search;
	if (param.indexOf('embed=true') >= 0) {
		$('.header').remove();
		$('.footer').remove();
	}
	//////////////  UI event binding   /////////////
	$('.js-signin').click(function () {
		// on reg_1 only show NL login
		if (window.APP_ID === 'dtc') {
			window.playDeepLink = '';
			showSignIn('signInFrame');
			// other pages show sign in options
		} else {
			showSignIn('signInOptionsFrame');
		}
	})
	$('.js-close').click(function () {
		var name = $(this).parents('.js-modal').data('name');
		var closeModal = $('.js-modal[data-name=' + name + ']');
		hideOverlay(closeModal);
		if (name == 'accounttemporary') {
			setTimeout(showUpdateNoticePopup, 500);
		}
	})
	$('.js-show-signin').click(function () {
		showSignIn('signInFrame');
	})
	$('.js-show-mvpd').click(function () {
		showSignIn('signInOptionsFrame');
	})
	$('.js-show-menu').click(function () {
		$('.js-header__item').hasClass('is-open') && $('.js-header__item').removeClass('is-open');
		$(this).toggleClass('is-open');
		$('.js-header__menu').toggleClass('is-open');
		$('body').toggleClass('is-menu-open');

		var smartAppBannerHeight = ($('body').hasClass('sab-opened')) ? $('#smartAppBanner').height() : 0;
		var headerAddHeight = isTVE ? $('.header-add').height() : 0;

		$('.js-header__menu').hasClass('is-open') && $('.js-header__menu').height($(window).height() - 80 - smartAppBannerHeight - headerAddHeight);
		$(window).resize(function () {
			var width = $(window).width();
			if (width > 767) {
				$('body').hasClass('is-menu-open') && $('body').removeClass('is-menu-open');
				$('.js-header__menu').css('height', 'auto');
			} else {
				if ($('.js-header__menu').hasClass('is-open')) {
					!$('body').hasClass('is-menu-open') && $('body').addClass('is-menu-open');
					$('.js-header__menu').height($(window).height() - 80 - smartAppBannerHeight - headerAddHeight);
				}
			}
		})
	})
	$('.js-language').click(function () {
		$(this).toggleClass('is-open');
	})
	$('.js-header__item').click(function () {
		$(this).toggleClass('is-open');
	})

	$('.js-header__personal').on('click', function () {
		$('.js-sidebar__text[data-id="watchlist"]').click();
		NLTracker.trackPage('/watchlist');
	})
	$('.js-sidebar__text').on('click', function () {
		showSidebar($(this));
	})
	$('.js-mask').click(function () {
		if (($(this).data('name') == 'sidebar') && $('.js-mask').hasClass('is-open')) {
			$('.js-header__sidebar').removeClass('is-open');
			$('.js-mask').removeClass('is-open');
		}
	})

	// //Account Hold
	$('.js-account-hold').click(function () {
		setCookie(haveReadCookieName, 'true');
	});
	if ((pageName == 'home' || pageName == 'channels' || pageName == 'categories' || pageName == 'category') && getCookie(haveReadCookieName) != 'true') {
		checkAccountSuspendedFlag();
	}


	//////////////  [UNI-3084] Cookie disabled prompting  /////////////
	if (!navigator.cookieEnabled) {
		// DOM only exists on landing page
		$('#cookie_disabled').insertBefore('body>header')
				.css({
					display: 'flex',
				});
		$('#cookie_disabled #close').click(function () {
			$('#cookie_disabled').slideUp();
			$('.header.header-sticky').css('top', 0);
		});
	}
	//////////////  Stick header  /////////////
	$(window).scroll(function () {
		stickyHeader();
	})
	//////////////  Get Channel Regions  /////////////
	if (pageName != 'channels' && pageName != 'home' && pageName != 'channel') {
		window.getAllChannelRegion && window.getAllChannelRegion();
	}
	//////////////  Adobepass  /////////////
	if (typeof initAdobePass == 'function') {
		initAdobePass();
	}

	$(window).on('orientationchange', function () {
		try {
			if (window.orientation === 90 || window.orientation === -90) {
				if (!nlIsAdPlaying()) nlHTML5GoFullscreen();
			}
			if (window.orientation === 180 || window.orientation === 0) {
				nlHTML5ExitFullscreen();
			}
		} catch (e) {
		}
		// $(window).trigger("orientationchange");
	});

	//[UNI-5420] Deep link to MVPD picker
	if (/signin=true/.test(window.location.href)) {
		if (window.isTVE) {
			window.neuap && neuap.getUserStatus(function (data) {
				if (data.status == 'anonymous') {
					showSignIn('signInOptionsFrame');
				}
			});
		} else {
			(!isLoggedIn) && showSignIn('signInFrame');
		}
	}
})

function popupHandler() {
	if ($('.js-header__item').hasClass('is-open')) {
		$('.js-header__item').removeClass('is-open');
	}
}

function stickyHeader() {
	var scrollTop = $(window).scrollTop();
	var height = $('.header').outerHeight();
	var addHeight = 0;
	if (isTVE) {
		addHeight = $('.header-add').outerHeight();
	}
	if (scrollTop >= addHeight) {
		$('.NavProvider__Wrapper').addClass('is-hidden');
		$('.NavProvider__Alert').addClass('is-hidden');
	} else {
		$('.NavProvider__Wrapper').removeClass('is-hidden');
		$('.NavProvider__Alert').removeClass('is-hidden');
	}

	if ($('#cookie_disabled')[0])
		var heightOfWarning = $('#cookie_disabled')[0].scrollHeight;
	if (scrollTop >= height) {
		$('.js_placeholder').show();
		$('.js_placeholder').css('height', height + 'px');
		$('.header').addClass('header-sticky')
				.css('top', heightOfWarning);
		$('#cookie_disabled').addClass('warning-sticky');
	} else {
		$('.header').removeClass('header-sticky');
		$('#cookie_disabled').removeClass('warning-sticky');
		$('.js_placeholder').hide();
		$('.js_placeholder').css('height', 0);
	}
}

var loadedSignIniFrame = false;
var loadedMVPDiFrame = false;

function showSignIn(id, hasFreeSampleEnded) {
	var modalClass = '';
	if (id == 'signInFrame') {
		modalClass = '.js-modal-signin';
		if (!loadedSignIniFrame) {
			loadedSignIniFrame = true;
			var url = location.href;
			if (url.indexOf('?') != -1)
				url = url.substr(0, url.indexOf('?'));
			url = LOC_SERVER + 'secure/signin?parent=' + encodeURIComponent(url);
			if (window.pendingGame) {
				var gameDeeplink = location.protocol + '//' + location.host + '/' + gamePath + '/' + pendingGame.seoName;
				url += '&top=' + encodeURIComponent(gameDeeplink);
			}
			document.getElementById(id).src = url;
		} else {
			$('#signInFrame').contents().find('.labelErrorDesc').each(function () {
				$(this).css('display', 'none');
			})
			if ($('#signInFrame').contents().find('#iptvauth_page_login').css('display') === 'none') {
				$('#signInFrame').contents().find('#iptvauth_page_login').css('display', 'block');
				$('#signInFrame').contents().find('#iptvauth_page_forgotpassword').css('display', 'none');
			}
			if (!isLoggedIn && $('#signInFrame').contents().find('#iptvauth_linkme').get(0).checked) {
				$('#signInFrame').contents().find('#iptvauth_linkme').get(0).checked = false;
				$('#signInFrame').contents().find('#iptvauth_field_username').val('');
				$('#signInFrame').contents().find('#iptvauth_field_password').val('');
				$('#signInFrame').contents().find('#iptvauth_field_forgotpassword_username').val('');
			}
		}
		$('body').addClass('is-signin-open');
		showOverlay(modalClass);
	} else if (id == 'signInOptionsFrame') {

		modalClass = '.js-modal-mvpd';
		if (!loadedMVPDiFrame) {
			$(modalClass).addClass('is-open-custom');
			loadedMVPDiFrame = true;
			url = '/signinoptions';
			(currentPurchase === 'tve') && (url += '?mvpd=true');
			hasFreeSampleEnded && (url = setURLParam('hasFreeSampleEnded', 'true', url));
			document.getElementById(id).src = url;

			if (!$('body').hasClass('is-overlay-open')) {
				$('body').addClass('is-overlay-open');
			}
			if (!$('.js-overlay').hasClass('is-overlay-open')) {
				$('.js-overlay').addClass('is-overlay-open');
			}
		} else {
			if (hasFreeSampleEnded) {
				$('#signInOptionsFrame').contents().find('#signInOptionsModal').addClass('hasFreeSampleEnded');
			} else {
				$('#signInOptionsFrame').contents().find('#signInOptionsModal').removeClass('hasFreeSampleEnded');
			}
			if ($(modalClass).hasClass('is-open') || $(modalClass).hasClass('is-open-custom')) {
				return false;
			}
			$(modalClass).addClass('is-open-custom');
			currentPurchase = null;
			showOverlay(modalClass);
		}
	}
	// Page View Tracking for Unow & MVPD Signin window
	if (id === 'signInFrame')
		NLTracker.trackPage('/univisionnowsignin');
	else if (id === 'signInOptionsFrame') {
		if (hasFreeSampleEnded) {
			NLTracker.trackPage('/tvepickerfreepreview');
		} else {
			NLTracker.trackPage('/tvepicker');
		}
	}

}

function showOverlay(dom) {
	if ($(dom).hasClass('is-open')) {
		return false;
	}

	if ($('.js-mask').hasClass('is-open')) {
		$('.js-mask').click();
	}
	$('.js-modal').removeClass('is-open');

	setModalHeight(dom);
	if (dom !== '.js-modal-mvpd') {
		$(window).resize(function () {
			if ($('.js-overlay').hasClass('.is-overlay-open')) {
				setModalHeight(dom);
			}
		});
	}

	if (!$('body').hasClass('is-overlay-open')) {
		$('body').addClass('is-overlay-open');
	}
	if (!$('.js-overlay').hasClass('is-overlay-open')) {
		$('.js-overlay').addClass('is-overlay-open');
	}
	$(dom).hasClass('is-open-custom') && $(dom).removeClass('is-open-custom');
	$(dom).addClass('is-open');
	// Page View Tracking for Overlays
	if (dom === '.js-modal-all-signin') {
		NLTracker.trackPage('/signin');
	} else if (dom === '.js-channelNetwork-lock') {
		NLTracker.trackPage('/channellocksubscription');
	} else if (dom === '.js-channelCable-lock') {
		NLTracker.trackPage('/channellocktve');
	} else if (dom === '.js-premium-mvpd-lock') {
		NLTracker.trackPage('/episodelock7day');
	} else if (dom === '.js-vod-lock') {
		NLTracker.trackPage('/episodelocksubscription');
	}
}

function setModalHeight(dom) {
	if (dom === '.js-modal-mvpd') {
		var mvpdHeight = $('#signInOptionsFrame').contents().find('#step1Table').outerHeight(true);
		$(dom).css('height', mvpdHeight + 'px');
	}
	$('.js-modal-wrap').css('height', $(dom).height() + 'px');
}

function showPlayerOverlay(dom) {
	if (!$('body').hasClass('is-overlay-open')) {
		$('body').addClass('is-overlay-open');
	}
	if (!$('.js-overlay-player').hasClass('is-overlay-open')) {
		$('.js-overlay-player').addClass('is-overlay-open');
	}
	$(dom).addClass('is-open');
}

function showPlayer() {
	showPlayerOverlay('.js-modal-player');
}

function removeModalClass(dom) {
	$('.js-modal-wrap').css('height', 0);
	$('.js-overlay').removeClass('is-overlay-open');
	$('body').removeClass('is-overlay-open');
	if (dom.hasClass('is-hidden')) {
		dom.removeClass('is-hidden');
	}
	dom.removeClass('is-open');
}

function removePlayerModalClass(dom) {
	$('.js-overlay-player').removeClass('is-overlay-open');
	$('body').removeClass('is-overlay-open');
	dom.removeClass('is-open');
}

function hideOverlay(dom, isClearOverlay) {
	if (dom.hasClass('is-open') && dom.hasClass('js-modal-signin') && $('body').hasClass('is-signin-open')) {
		$('body').removeClass('is-signin-open');
	}

	if (dom.hasClass('js-modal-mvpd') && $('#signInOptionsFrame').contents().find('#signInOptionsModal').hasClass('hasFreeSampleEnded')) {
		_FreeSample && _FreeSample.reload();
	}

	if (dom.hasClass('is-open') && !dom.hasClass('js-modal-player')) {
		if (dom.hasClass('js-modal-pc')) {
			removeModalClass(dom);
		} else {
			dom.addClass('is-hidden');
			setTimeout(function () {
				removeModalClass(dom)
			}, 500);
		}
	} else {
		if (isClearOverlay) {
			$('.playerFrame').remove()
		} else {
			$('.playerFrame').hide();
		}
		removePlayerModalClass(dom);
	}
}

function showSidebar(btn) {
	var $this = btn;
	var dataId = $this.data('id');
	!$('.js-header__sidebar').hasClass('is-open') && $('.js-header__sidebar').addClass('is-open');
	!$('.js-mask').hasClass('is-open') && $('.js-mask').addClass('is-open');
	!$('.js-mask').data('name') && $('.js-mask').data('name', 'sidebar');
	$this.siblings('.js-sidebar__text').removeClass('is-selected').end().addClass('is-selected');
	$('.js-sidebar[data-id=' + dataId + ']').siblings('.js-sidebar').removeClass('is-selected').end().addClass('is-selected');
	if (!(watchlistScroller && resumeScroller && $('.js-sidebar__content .loading').is(':hidden'))) {
		$('.js-sidebar__content .loading').removeClass('hide').addClass('show');
	}
	var smartAppBannerHeight = ($('body').hasClass('sab-opened')) ? $('#smartAppBanner').height() : 0;

	$('.js-sidebar__content').css('maxHeight', ($(window).innerHeight() - 110 - smartAppBannerHeight) + 'px');
	$('.js-sidebar').css('maxHeight', ($(window).innerHeight() - 110 - smartAppBannerHeight) + 'px');
	$(window).scroll(function () {
		$('.js-sidebar__content').css('maxHeight', ($(window).innerHeight() - 110 - smartAppBannerHeight) + 'px');
		$('.js-sidebar').css('maxHeight', ($(window).innerHeight() - 110 - smartAppBannerHeight) + 'px');
		watchlistScroller && watchlistScroller.refresh();
		resumeScroller && resumeScroller.refresh();
	})
	$(window).resize(function () {
		$('.js-sidebar__content').css('maxHeight', ($(window).innerHeight() - 110 - smartAppBannerHeight) + 'px');
		$('.js-sidebar').css('maxHeight', ($(window).innerHeight() - 110 - smartAppBannerHeight) + 'px');
	});

	if (dataId == 'watchlist') {
		showWatchList(function () {
			$('.js-sidebar__content').css('height', $('.js-sidebar[data-id=' + dataId + '] .js-sidebar__list').height() + 'px');
			$('.js-sidebar[data-id=' + dataId + ']').css('height', $('.js-sidebar[data-id=' + dataId + '] .js-sidebar__list').height() + 'px');
			$('.js-watchlist img.lazy').lazyload({
				container: '.js-watchlist',
				event: 'scroll mousemove touchmove pointermove DOMMouseScroll mousewheel MSPointerMove wheel',
				effect: 'fadeIn'
			});
			watchlistScroller = new IScroll('.js-watchlist', {
				mouseWheel: true,
				scrollbars: 'custom',
				click: true,
				interactiveScrollbars: true
			});
			watchlistScroller.on('scrollEnd', function () {
				$('.js-watchlist').trigger('scroll');
			});
		})
	} else {
		showResume(function () {
			$('.js-sidebar__content').css('height', $('.js-sidebar[data-id=' + dataId + '] .js-sidebar__list').height() + 'px');
			$('.js-sidebar[data-id=' + dataId + ']').css('height', $('.js-sidebar[data-id=' + dataId + '] .js-sidebar__list').height() + 'px');

			if (resumeScroller) {
				resumeScroller.destroy();
				resumeScroller = null;
			}
			$('.js-resume img.lazy').lazyload({
				container: '.js-resume',
				event: 'scroll mousemove touchmove pointermove DOMMouseScroll mousewheel MSPointerMove wheel',
				effect: 'fadeIn'
			});
			resumeScroller = new IScroll('.js-' + dataId, {
				mouseWheel: true,
				scrollbars: 'custom',
				click: true,
				interactiveScrollbars: true
			});
			resumeScroller.on('scrollEnd', function () {
				$('.js-resume').trigger('scroll');
			});
		})
	}
}

function showWatchList(callback) {
	getWatchList('sidebar', function (obj) {
		renderSidebarUI(obj, 'watchlist', callback);
	});
}

function showResume(callback) {
	programPositionChange();
	$(document).on('programPositionChange', function (event, data) {
		var newData = $.extend(true, {}, data)
		renderSidebarUI(newData, 'resume', callback);
	})
}

function renderSidebarUI(obj, name, callback) {
	$('.js-sidebar__content .loading').removeClass('show').addClass('hide');
	var sidebarTemplate = Handlebars.compile($('#sidebarTemplate').html());

	if (obj.list.length > 0) {
		obj.hasItem = true;
	} else {
		obj.hasItem = false;
		$('.js-' + name).addClass('no-items');
	}
	if (name == 'resume') {
		obj.isResume = true;
		var items = obj.list;
		for (var i in items) {
			items[i].programLink = items[i].seoname;
		}
	}
	$('.js-' + name).html(sidebarTemplate(obj));
	callback && callback();
}

//Regions
function initRegionsScroller() {
	setTimeout(function () {
		if (!regionsScroller) {
			regionsScroller = new IScroll('.js-regions__list', {
				mouseWheel: true,
				scrollbars: 'custom',
				click: true,
				interactiveScrollbars: true
			});
		}
	}, 350);
}

//DTC goto TVE
function redirectToTVE() {
	var url = window.TVE_DOMAIN;
	if (window.playDeepLink !== '') {
		url = window.TVE_DOMAIN + '/' + window.playDeepLink;
	}
	window.location.href = url;
}


//Check if come from locked vod/channel
function handlePlayDeepLink() {
	window.customRedirectUrl = '';
	if (getCookie('playdeeplink')) {
		if (pageName == 'packages' || pageName == 'subscription') {
			window.customRedirectUrl = decodeURIComponent(getCookie('playdeeplink'));
		} else {
			setCookie('playdeeplink', null, -1);
		}
	}
}

//AUTH POPUP
//showAuthPopup("1", "2017/07/21");
function showAuthPopup(purchaseType, resourceId) {
	currentPurchase = purchaseType;
	var hasSigninParam = /signin=true/.test(window.location.href) ? true : false;
	if (window.APP_ID === 'dtc') {
		document.cookie = 'playdeeplink=' + encodeURIComponent(window.playDeepLink) + ';path=/;';
	}

	if (userType == 'anonymous') {
		if (window.APP_ID === 'dtc') {
			(!hasSigninParam) && (window.location.href = window.LOC_SERVER + 'packages?type=0');
		} else {
			(!hasSigninParam) && showSignIn('signInOptionsFrame');
		}
	} else {
		var isDTCUser = false;
		var isTveUnivision = false;
		var isTveGalavision = false;
		var isTveDeportes = false;
		var isTveFusion = false;
		var isTveSubscriber = isTVE ? true : false;
		if (userAuth && userAuth.length > 0) {
			for (var i = 0; i < userAuth.length; i++) {
				switch (userAuth[i]) {
					case 'isDTCUser':
						isDTCUser = true;
						break;
					case 'tve-auth-univision':
						isTveUnivision = true;
						break;
					case 'tve-auth-galavision':
						isTveGalavision = true;
						break;
					case 'tve-auth-deportes':
						isTveDeportes = true;
						break;
					case 'tve-auth-fusion':
						isTveFusion = true;
						break;
				}
			}
			if (isTVE && (isTveUnivision || isTveGalavision || isTveDeportes || isTveFusion)) {
				isTveSubscriber = false;
			}
		}

		if (purchaseType == 'all') {
			if (isTveUnivision || isTveGalavision || isTveDeportes || isTveFusion || isTveSubscriber) {
				showOverlay('.js-channelNoaccess-lock');
			} else {
				showOverlay('.js-channelNetwork-lock');
			}
		} else if (purchaseType == 'tve') {
			// need login with other mvpd account
			if (isTveUnivision || isTveGalavision || isTveDeportes || isTveFusion || isTveSubscriber) {
				showOverlay('.js-channelNoaccess-lock');
			} else {
				showOverlay('.js-channelCable-lock');
			}
		} else if (purchaseType == PROG_PUR_TYPES.DTV_AND_TVE)// dtc+tve
		{
			if (window.APP_ID === 'dtc') {
				showOverlay('.js-premium-lock');
			} else {
				showOverlay('.js-vod-lock');
			}
		} else if (purchaseType == PROG_PUR_TYPES.TVE_ONLY)// tve
		{
			if (window.APP_ID === 'tve') {
				showOverlay('.js-vod-lock');
			} else {
				showOverlay('.js-vodNoaccess-lock');
			}
		} else if (purchaseType == PROG_PUR_TYPES.DTC_ONLY)//dtc
		{
			//Check Account Hold first
			checkAccountSuspendedFlag(function () {
				if (window.APP_ID === 'dtc') {
					showOverlay('.js-premium-lock');
				} else {
					showOverlay('.js-vod-lock');
				}

			});
		}
	}
}

/*----------------------mvpd asyn------------------------------*/

/* Common function to handle asyn process on TVE ( header/myaccount, header/watchlist, sidebar, video/channel auth, category/watchlist) */
function waitFinalStatus(callback) {
	if (!isTVE || isLatestMVPD == true) {
		callback && callback();
	} else {
		var loadingInterval = window.setInterval(function () {
			if (isLatestMVPD == true) {
				clearInterval(loadingInterval);
				loadingInterval = null;
				callback && callback();
			}
		}, 300);
	}
}

/*-------------------------Account Hold-----------------------*/
var suspendedCookieName = 'userSuspended';
var haveReadCookieName = 'haveRead';

//Account Hold Check
function checkAccountSuspendedFlag(callback) {
	var suspended = getCookie(suspendedCookieName);
	if (window.isLoggedIn && suspended) {
		if (suspended == 'true') {
			showAccountHoldPopup('suspended');
			return false;
		} else {
			callback && callback();
		}
	} else {
		{
			$.getJSON('/account/subscriptions?format=json', function (data) {
				if (data.suspended && data.suspended.length > 0) {
					setCookie(suspendedCookieName, true, 60);
					showAccountHoldPopup('suspended');
					return false;
				} else {
					setCookie(suspendedCookieName, false, 60);
					callback && callback();
				}
			})
		}
	}
}

function clearAccountSuspendedFlag() {
	if (document.cookie.indexOf(suspendedCookieName + '=') != -1) {
		setCookie(suspendedCookieName, '');
		setCookie(haveReadCookieName, '');
	}
}

function clearVIPRegion() {
	if (document.cookie.indexOf('chooseRegion=') != -1) {
		setCookie('chooseRegion', '');
	}
}

function showAccountHoldPopup() {
	showOverlay('.js-account-hold');
}

function setCookie(name, value, mins) {
	var expires;
	if (mins) {
		var date = new Date();
		date.setTime(date.getTime() + (mins * 60 * 1000));
		expires = '; expires=' + date.toGMTString();
	} else {
		expires = '';
	}
	document.cookie = name + '=' + value + expires + '; path=/';
}

function getCookie(name) {
	if (document.cookie.length > 0) {
		var startIdx, endIdx;
		startIdx = document.cookie.indexOf(name + '=');
		if (startIdx != -1) {
			startIdx = startIdx + name.length + 1;
			endIdx = document.cookie.indexOf(';', startIdx);
			if (endIdx == -1) {
				endIdx = document.cookie.length;
			}
			return unescape(document.cookie.substring(startIdx, endIdx));
		}
	}
	return null;
}

/*-------------------------Account Hold-----------------------*/

/*--------------------------Temporary User------------------------------*/
function showAccountTemporaryPopup() {
	if (document.cookie.indexOf('hasInterstitialScreen=') == -1 && pageName != 'tos' && pageName != 'privacy') {
		showOverlay('.js-account-temporary');
		var expires = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1);
		document.cookie = 'hasInterstitialScreen=true; expires=' + expires + '; path=/';
	}
}

/*--------------------------Temporary User------------------------------*/

/*-------------------------Provicy Policy Update Notice----------------*/
function showUpdateNoticePopup() {
	if (window.PRIVACY_POLICY_UPDATE_NOTICE && document.cookie.indexOf('hasAcceptUpdate=') == -1 && pageName != 'tos' && pageName != 'privacy') {
		window.top.showOverlay('.js-update-notice');
	}
}

function accetpUpdate() {
	var expires = new Date(new Date(new Date().toLocaleDateString()).getTime() + 30 * 24 * 60 * 60 * 1000 - 1);
	document.cookie = 'hasAcceptUpdate=true; expires=' + expires + '; path=/';
}

/*-------------------------Provicy Policy Update Notice----------------*/

function gotoHome() {
	window.open(LOC_SERVER, '_parent');
}
