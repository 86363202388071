(function (root, factory) {
	root['Search'] = factory();
}(this, function () {
	/*
	 *	pn: search result number
	 *
	 * */
	function InitSeach(config)
	{

		var containerTemp = '<div class="search-container"><div class="container"><div class="search-header"><div><span class="search-back"></span><div class="header-content"><input class="search-input" type="text" placeholder="' + NEU.util.getLocalizedString('search_placeholder') + '"/>' +
				'<i class="search-delete"></i></div></div></div><div class="loading"></div><div class="search-result row"></div><div class="more"><a class="load-more">' + NEU.util.getLocalizedString('load_more') + '</a></div></div></div>';
		//TODO search progame link
		var searchListItemTemp = '<div class="program program--center col-xs-12 col-sm-6 col-md-4 col-lg-3"><a href="/category/{seoname}" class="program__img-link"><img class="img-responsive program__img" src="' + LOC_CONTENT + 'categories/{image}_eb.jpg" alt="{title}" title="{title}" ' +
				' onerror="this.onerror=null;this.src=\''+LOC_RESOURCE+SITE_ID+aliasPath+'/site_4/images/default_eb.png\';"'+
				'/>' +'{program_tag}'+
				'</a></div>';
		var noContent = '<div><div class="no-content"><div>' + NEU.util.getLocalizedString("search_no_result_title") + ' "{value}"</div><div>' + NEU.util.getLocalizedString("search_no_result") + '</div></div></div>';
		var searchContainer = $('<div id="searchContainer"></div>');
		var defaultConfig = {
			pn: 8,
			containerId: '#searchContainer' //hard code
		};

		var owner = this;
		this.startNum = 0;
		this.query = null;
		this.needRefresh = false;
		this.timer = null;
		this.hasSearchShow = false;
		this.options = $.extend(true, {}, defaultConfig, config);
		this.show = function () {
			$('body').hasClass('is-menu-open') && $('body').css('position', 'initial');
			$("html,body").animate({"scrollTop": 0}, 300);
			if ($('body ' + this.options.containerId).html() == undefined)
			{
				$('body').append(searchContainer);
				owner.$containerId = $(this.options.containerId);
				this.renderSearchContainer();
			}
			var $searchContainer = $('body ' + this.options.containerId);
			$searchContainer.height($(document).height());
			$searchContainer.addClass('show').removeClass('close');
			this.hasSearchShow = true;
			$(window).unbind('scroll', 'searchLazyload').bind('scroll', searchLazyload);
		}

		function searchLazyload()
		{
			var $searchContainer = $('body ' + owner.options.containerId);
			var $documentHeight = $(document).height()
			var scrollTop = $(window).scrollTop();
			var $searchContainerHeight = $searchContainer.height();
			var domTop = $(owner.options.containerId + ' .search-result').offset().top;
			var domHeight = $(owner.options.containerId + ' .search-result').height();
			var windowHeight = $(window).height();
			var domTopHeight = domTop + domHeight;
			if ($documentHeight !== $searchContainerHeight)
			{
				$searchContainer.height($documentHeight);
			}
			if (scrollTop + windowHeight >= domTopHeight)
			{
				if (!$('.more', owner.$containerId).is(':hidden'))
				{
					$('.load-more', owner.$containerId).click()
				}
			}
		}

		function escapeSolrSpecialChars(chars)
		{
			return chars.replace(/(\s)/g, "\\$1");
		}

		this.hide = function () {
			this.hasSearchShow = false;
			$('body').hasClass('is-menu-open') && $('body').css('position', 'fixed');
			$('body ' + this.options.containerId).removeClass('show').addClass('hide');
		}

		this.renderSearchContainer = function () {
			$('body ' + this.options.containerId).html(containerTemp);
			this.searchInputHandle();
			this.loadMoreHandle();
			this.backHandle();
			windowResize()
		};

		this.backHandle = function () {
			var _containerId = this.options.containerId;
			$(_containerId).on('click', '.search-back', function () {
				$(_containerId).addClass('close')
				setTimeout(function () {
					$('body').hasClass('is-menu-open') && $('body').css('position', 'fixed');
					$(_containerId).removeClass('show').hide()
				}, 750)
			})
		}

		this.searchInputHandle = function () {
			$(document).on('keyup', this.options.containerId + ' .search-input', function () {
				var searchVal = $(this).val();
				owner.searchValue = searchVal;
				if (searchVal != '')
				{
					owner.deleteIconShow(true);
					this.timer && clearTimeout(this.timer);
					this.timer = setTimeout(function () {
						$('.search-result', owner.$containerId).html('');
						owner.needRefresh = true;
						owner.startNum = 0;
						owner.loadingShowHandle(true);
						owner.getProgamesOfSearch(searchVal);
					}, 300);
				}
				else
				{
					owner.deleteIconShow(false);
				}
			})
			owner.deleteHandle();
		}

		this.deleteHandle = function () {
			$('.search-delete', owner.$containerId).on('click', function () {
				$('.search-input', owner.$containerId).val('').focus();
				owner.deleteIconShow(false);
			})
		}

		this.deleteIconShow = function (isShow) {
			$('.search-delete', owner.$containerId).toggle(isShow);
		}

		this.loadingShowHandle = function (isShow) {
			$('.search-container .loading', owner.$containerId).toggle(isShow);
		}

		this.getProgamesOfSearch = function (val) {
			this.query = val;
			var solrLocale = window.isDefaultContentLocale ? "" : "&locale=" + window.locale;
			var query = encodeURIComponent('parentId:' + showsRootCatId + ' AND nameExact:*' + escapeSolrSpecialChars(val) + '* AND status:2 ');
			var searchProgramFuncName = 'searchProgame' + owner.$containerId.attr('id');
			$.getScript(SOLR_CATEGORY + window.SOLR_HANDLER + '?q=' + query + solrLocale + '&start=' + this.startNum + '&rows=' + this.options.pn + '&wt=json&indent=true&fl=seoName,description,name,catId,style,code&json.wrf=' + searchProgramFuncName);
			if (!window[searchProgramFuncName])
			{
				window[searchProgramFuncName] = function (data) {
					owner.resultNum = data.response.numFound;
					owner.startNum = data.response.start + data.response.docs.length;
					owner.renderSearchList(data.response.docs);
					owner.checkLoadMore();
				}
			}
		}

		this.renderSearchList = function (progames) {
			var data, source, template;
			if (progames.length == 0)
			{
				template = noContent.replace(/\{value}/gi, owner.searchValue);
			}
			else
			{
				data = owner.processProgames(progames);
				template = data.map(function (item) {
					var program_tag = item.style ? '<img class="category__tag tag-img-eb hidden-xs" alt="" src="' + LOC_RESOURCE + SITE_ID + '/site_4/images/' + item.style.toLowerCase() + '_eb.png">'+'<img class="category__tag tag-img-eb hidden-sm hidden-md hidden-lg" src="' + LOC_RESOURCE + SITE_ID + '/site_4/images/' + item.style.toLowerCase() + '_bg.png">' : '';
					return $(searchListItemTemp.replace(/\{seoname}/gi, item.seoname).replace(/\{image}/gi, item.image).replace(/{title}/gi, item.title).replace(/{program_tag}/gi, program_tag));
				})
			}
			this.loadingShowHandle(false);
			if (owner.needRefresh)
			{
				$('.search-result', $('body')).html(template);
			}
			else
			{
				$(' .search-result', owner.$containerId).append(template);
			}

		}

		this.processProgames = function (progames) {
			var retProgames = progames.map(function (progame) {
				return {
					image: progame.catId,
					seoname: progame.seoName,
					title: progame.name,
					style: progame.style ? progame.style : ''
				}
			})
			return retProgames;
		}
		this.checkLoadMore = function () {
			if (this.startNum < this.resultNum)
			{
				owner.loadMoreDisplay(true)
			}
			else
			{
				owner.loadMoreDisplay(false)
			}
		}
		this.loadMoreDisplay = function (isShow) {
			$('.more', owner.$containerId).toggle(isShow)
		}
		this.loadMoreHandle = function () {
			$('.load-more', owner.$containerId).on('click', function () {
				owner.loadMoreDisplay(false)
				owner.needRefresh = false;
				owner.getProgamesOfSearch(owner.query);
			})
		}

		function windowResize()
		{
			var $searchContainer = $('body ' + owner.options.containerId);
			$(window).resize(function () {
				if (owner.hasSearchShow)
				{

					$searchContainer.height($(document).height());
				}
			})
		}
	}

	return InitSeach;
}));
var searchModal = null;
$(document).ready(function () {
	if ($('.js_search').html() !== undefined)
	{
		$('.js_search').on('click', function () {
			if (searchModal == null)
			{
				searchModal = new Search();
			}
			searchModal.show();
			NLTracker.trackPage("/search");
		})
	}
})
