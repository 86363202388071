// Solr Functions
function getGenres(callback)
{
	// Sample: https://api.neulion.net.cn/solr/W_CAT_UNINOWV2/dtcsearch?q=parentId:302%20AND%20status:2&fl=name,seoName,style&rows=100&locale=es_US&sort=rank+asc&wt=json&json.wrf=genresListCallback
	var solrLocale = window.isDefaultContentLocale ? "" : "&locale=" + window.locale;
	var sortQuery = "&sort=rank_" + window.categoryRootId + "%20asc,score%20desc,catId%20desc";
	var genresSolrFeed = window.SOLR_CATEGORY + window.SOLR_HANDLER + "?q=parentId:" + window.categoryRootId + "%20AND%20status:2&fl=name,seoName,style,code&rows=100" + solrLocale + sortQuery + "&wt=json&json.wrf=genresListCallback";
	NEU.util.getJSONPFeed(genresSolrFeed, "genresListCallback", callback);
}

function getShowsByParentCatId(catId, pageNum, pageSize, callback)
{
	var fq = "parentIds:" + catId;
	var solrLocale = window.isDefaultContentLocale ? "" : "&locale=" + window.locale;
	var sortQuery = "&sort=rank_" + catId + "%20asc,score%20desc,catId%20desc";
	var genreSolrFeed = window.SOLR_CATEGORY + window.SOLR_HANDLER + "?q=*:*" + solrLocale + "&fq=" + fq + "&fl=seoName,description,name,catId,style,code" + "&start=" + pageNum * pageSize + "&rows=" + pageSize + sortQuery + "&wt=json&json.wrf=categoryListCallback";
	NEU.util.getJSONPFeed(genreSolrFeed, "categoryListCallback", callback);
}

function countdownHandle(date, duration)
{
	var dateGMT = date.replace(/\.\d{3}/, "Z");
	var localTimezone = moment.tz.guess();
	var localDate = moment.tz(dateGMT, localTimezone).format();
	return NEU.util.formatDate(localDate, 'h:mma') + ' - ' + NEU.util.formatDate(new Date(NEU.util.parseDate(localDate).getTime() + parseInt(duration * 60, 10) * 1000), 'h:mma');
}

/* start current program */
var currentChannels = [], regionList = [], channelsMap = [], channelGroupingOrder = [], callbackArray = [];
function getCurrentChannel(callback)
{
	var timer = window.checkCurrentFeedTime || 10 * 60 * 1000;
	callbackArray.push(callback);
	if (!window.channelsTimer) {
		window.channelsTimer = setInterval(currentChannelHandle, timer, function (data) {
			callbackArray.forEach(function (callback) {
				callback && callback(data);
			})
		});
	}
}
var getAllChannelsCallbacks = [], isAllChannelsLoading = false;

function getChannels(callback)
{
	getAllChannelsCallbacks.push(callback);

	if(isAllChannelsLoading) {
		return;
	}

	isAllChannelsLoading = true;

	getAllChannelRegion(function (channelsRegion)
	{
		$.getJSON('/service/channels?format=json&t=' + new Date().getTime(), function (data)
		{
			isAllChannelsLoading = false;
			getAllChannelsCallbacks.forEach(function (cb) {
				cb && cb(classifyChannels(data, channelsRegion));
			})
		})
	})
}
var g_channelsRegionData = null;
function getAllChannelRegion(callback)
{
	if(g_channelsRegionData) {
		getAllChannelRegionHandle(g_channelsRegionData);
	} else {
		NEU.util.getJSON(window.channelsRegion + '?t=' + new Date().getTime(), getAllChannelRegionHandle)
	}
	function getAllChannelRegionHandle(data)
	{
		if(!g_channelsRegionData) {
			g_channelsRegionData = data;
		}
		var regions = [], currentRegion = contentRegions[0].name;
		var channelsGrouping =data.grouping.params.order;
		channelGroupingOrder = channelsGrouping.sort(function (a, b) {
			value1 = a.order;
			value2 = b.order;
			if (value1 < value2)
			{
				return -1;
			}
			else if (value1 > value2)
			{
				return 1;
			}
			else
			{
				return 0;
			}
		}).map(function (item) {
			return item;
		});
		regions = data.channels.map(function (item)
		{
			return item.region || '';
		}).filter(function (item, idx, array)
		{
			return item != '' && array.indexOf($.trim(item)) === idx && item != 'ALL';
		}).sort();

		if (window.isVIP)
		{
			currentRegion = NEU.util.getCookie('chooseRegion') || currentRegion;
			var source = '';
			regions.forEach(function (item)
			{
				source += '<li data-target="' + item + '" class="regions__item ' + (item == currentRegion ? 'is-selected' : '') + '">' + item + '</li>'
			})
			var tempWrap = $('.regions__list ul');
			tempWrap.html(source);
			$('li:not(.is-selected)', tempWrap).on('click', function ()
			{
				var $target = $(this).data('target');
				NEU.util.setCookie('chooseRegion', $target);
				window.location.reload();
			})
		}
		channelsMap = data.channels;
		var channels = data.channels.filter(function (item)
		{
			return $.trim(item.region).toLowerCase() == currentRegion.toLowerCase() || item.region == undefined || item.region == 'ALL' || item.region == '';
		}).map(function (item)
		{
			return {seoName:item.seoName};
		});
		callback && callback(channels);
	}
}
var crrentChannelCallbackArray = [], isCrrentChannelLoading = false;
function currentChannelHandle(callback, callbackName)
{
	var currentChannelTimer = setTimeout(function ()
	{
		callback && callback();
	}, 3000);

	crrentChannelCallbackArray.push(callback);

	if (isCrrentChannelLoading) {
		return;
	}

	isCrrentChannelLoading = true;

	var url=CURRENT_CHANNEL_PREFIX + 'current' + (isDefaultContentLocale ? '' : '_' + locale) + '.json';
	$.getJSON(NEU.util.addTimestamp(url, 300), function (data)
	{
		isCrrentChannelLoading = false;
		clearTimeout(currentChannelTimer);
		currentChannels = data;
		crrentChannelCallbackArray.forEach(function (cb) {
			cb && cb(data);
		})
	});
}
function renderProgramStats($container)
{
	getCurrentChannel(function (data)
	{
		data.forEach(function (channel)
		{
			var $channelItem = $(".program[data-seoalias='" + channel.seoName + "']", $container);
			if ($channelItem.html() != undefined)
			{
				$('.program__title', $channelItem).text(channel.item[0].e);
				$('.program__duration', $channelItem).text(countdownHandle(channel.item[0].su, channel.item[0].d));
				if (channel.item[0].img)
				{
					var _image = /^http/gi.test(channel.item[0].img) ? channel.item[0].img : LOC_CONTENT + 'epg/' + channel.item[0].img;

					setTimeout(function ()
					{
						$('.program__img', $channelItem).attr('src', _image)
					}, 50)
				}
			}
		})
	})
}
function classifyChannels(channels, channelsRegion)
{
	var retChannels = {}, resultChannel = [];
	var filterChannel = channels.map(function (item) {
		var channel=null;
		channelsRegion.forEach(function (el) {
			if (el.seoName === item.seoName)
			{
				channel=item;
				return;
			}
		})
		return channel;
	}).filter(function (item) {
		return item!=null;
	})
	filterChannel.forEach(function (item)
	{
		if (item.grouping && (item.code === window.APP_ID || item.code === 'all'))
		{
			var groupingKey=item.grouping.split(':')[0];
			var key = groupingKey.replace(/\s+/g, '_'), _item = {};

			if (!retChannels[key])
			{
				var nameKey = 'name' + window.locale.substring(0, 2).toUpperCase();
				retChannels[key] = {
					list: [],
					title: findSpecifiedValueByKey(channelGroupingOrder, 'id', groupingKey, nameKey),
					grouping: groupingKey
				}
			}

			var currentChannelItem = window.currentChannels.filter(function (channel)
			{
				var _seoAlias = getChannelSeoAlias(item);
				return channel.seoName === _seoAlias;
			})[0];


			if (currentChannelItem)
			{
				_item.desc = currentChannelItem.item[0].e;
				_item.time = countdownHandle(currentChannelItem.item[0].su, currentChannelItem.item[0].d);
				if (currentChannelItem.item[0].img)
				{
					var _image = !!currentChannelItem.item[0].img ? /^http/gi.test(currentChannelItem.item[0].img) ? currentChannelItem.item[0].img : LOC_CONTENT + 'epg/' + currentChannelItem.item[0].img : LOC_CONTENT + 'epg/' + currentChannelItem.item[0].image
					_item.image = _image;
				}
				else
				{
					_item.image = setDefaultImage(item.id);
				}
			}
			else
			{
				_item.desc = item.description;
				_item.time = '';
				_item.image = setDefaultImage(item.id);
			}
			var _resourceIds = window.isTVE ? getResourceId(item.id) : '';
			_item.id = item.id;
			_item.name = item.name;
			_item.seoname = item.seoName;
			_item.seoAlias = getChannelSeoAlias(item);
			_item.resourceIdclass = window.isTVE ? getResourceIdClass(_resourceIds) : '';
			_item.logo = LOC_CONTENT + 'channels/' + item.id + '_es.png';
			_item.resourceId = _resourceIds;
			_item.accessType = item.code;
			_item.neulionAccess = item.noAccess ? 'noaccess' : 'access';
			_item.grouping = item.grouping;
			_item.groupId = item.grouping.split(':').length >= 2 ? item.grouping.split(':')[1] : '';
			retChannels[key].list.push(_item);
		}
	})
	for (var i in retChannels)
	{
		resultChannel.push(retChannels[i]);
	}
	var _resultChannel = resultChannel.sort(function (a, b)
	{

		var value1 = findSpecifiedValueByKey(channelGroupingOrder, 'id', a.grouping.split(':')[0], 'order');
		var value2 = findSpecifiedValueByKey(channelGroupingOrder, 'id', b.grouping.split(':')[0], 'order');
		if (value1 < value2)
		{
			return -1;
		}
		else if (value1 > value2)
		{
			return 1;
		}
		else
		{
			return 0;
		}
	})
	return _resultChannel;
}

/**
 * The SEO name of the actual channel if the market shares the region with other markets
 * @param channel
 * @returns {string}
 */
function getChannelSeoAlias(channel)
{
	var seoAlias = channel.grouping.split(':').length == 3 ? channel.grouping.split(':')[2] : channel.seoName;
	return seoAlias.toLowerCase();
}


function findSpecifiedValueByKey(jsonArray, key, value, specifiedKey)
{
	var specifiedValue = "";
	for (var i = 0; i < jsonArray.length; i++)
	{
		if (jsonArray[i][key] == value)
		{
			specifiedValue = jsonArray[i][specifiedKey];
			break;
		}
	}
	return specifiedValue;
}

function getResourceIdClass(resourceIds)
{
	var resourceIdclass = '';
	var resourceIdArray = resourceIds.split(',');
	if (resourceIds == '')
	{
		return 'tve-purchase-free';
	}
	else
	{
		resourceIdArray.forEach(function (item) {
			resourceIdclass = resourceIdclass + ' tve-purchase-' + item;
		})
	}

	return resourceIdclass;
}

function getResourceId(id)
{
	var resourceIdArray = [];
	for (var key in g_nlAdobePassResourceMap)
	{
		var purchaseIds = g_nlAdobePassResourceMap[key].LINEAR_CHANNEL_PURCHASE;
		for (var i = 0, len = purchaseIds.length; i < len; i++)
		{
			if (id == purchaseIds[i])
			{
				resourceIdArray.push(key);
				break
			}
		}
	}
	return resourceIdArray.join(',');
}
function setDefaultImage(channelId)
{
	return LOC_CONTENT + 'channels/' + channelId + '_eb.jpg'
}
/* end current program*/

//Available time for program
function getVodAvailableDate(purchaseTypeId, releaseDate)
{
	if (vodDelayAvailableMap[purchaseTypeId + ""])
	{
		return NEU.util.formatDate(new Date(NEU.util.parseDate(releaseDate).getTime()
				+ vodDelayAvailableMap[purchaseTypeId + ""] * 24 * 3600 * 1000), NEU.util.getLocalizedString("date_format_until"));
	}
	else
	{
		return null;
	}
}

/* show player start*/
$(window).on('popstate.default load.default', function ()
{
	if (this.history.state)
	{
		if (this.history.state.type == 'vodPlayer')
		{
			playProgram(this.history.state.seo_name)
		}
		else if (this.history.state.type == 'channelPlayer')
		{
			playChannel(this.history.state.seo_name)
		}
	}
})

function cancelHandler()
{
	if(window.history.state && window.history.state.type=='vodPlayer'){
		window.history.back();
	}
}

function checkProgramAccess(seoName, callback)
{
	NEU.util.getJSON(window.LOC_SERVER + 'video/' + seoName + '?format=json', function (data)
	{
		waitFinalStatus(function(){
			if ((!data.noAccess) || isTemporaryUser || (isMVPDLoggedIn && (userAuth && userAuth.length > 0) && (data.purchaseTypeId == PROG_PUR_TYPES.DTV_AND_TVE || data.purchaseTypeId == PROG_PUR_TYPES.TVE_ONLY)))
			{
				// Check Parental Controls
				if(!!window.ParentalControls){
					var parentalControls = new ParentalControls();
					parentalControls.check(data.tvRating, function () {callback && callback(true, data);}, cancelHandler);
				}else{
					callback && callback(true, data);
				}
			}
			else
			{
				if (window.isTVE && userType == 'anonymous') {
					var isFree = (!data.purchaseTypeId || data.purchaseTypeId == '0') ? true : false;
					_FreeSample && _FreeSample.check(isFree, function () {
						data.noAccess = false;//Support freesample for Deeplink
						callback && callback(true, data);
					});
				} else {
					showAuthPopup(data.purchaseTypeId);
					callback && callback(false, data);
				}

			}
		})
	})
}

var playDeepLink='';// 'channels?seoname=<channelSeoName>'; 'category/categorySeoName?video=<videoSeoName>'
var hasVodFrame = false;
function playProgram(seoname, playType, _target)
{
	window.playDeepLink = setURLParam('video',seoname.toLowerCase());
	if (_target)
	{
		waitFinalStatus(function(){
			var $parent = $(_target).parents('.program')
			var unlock = $parent.find('.js-program--icon-lock').is(':hidden');
			var purchaseType = $parent.attr('class').split(' ').filter(function(item){return /^purchase-type/gi.test(item)})[0].split('-')[2];
			if (!unlock)
			{
				if (window.isTVE && userType == 'anonymous') {
					var isFree = $parent.data('purchaseTypeId')=='0' ? true : false;
					_FreeSample && _FreeSample.check(isFree,function () {
						play(seoname, playType);
					});
				} else {
					showAuthPopup(purchaseType)
				}
			}
			else
			{
				// Check program parental controls
				var tvRating = $(_target).parents('.program').data("tvrating");
				if(!!window.ParentalControls){
					var parentalControls = new ParentalControls();
					parentalControls.check(tvRating, function () {setTimeout(function(){play(seoname, playType)},500);}, null);
				}else{
					play(seoname, playType);
				}
			}
		});

	}else{
		play(seoname, playType);
	}

}

function play(seoname, playType)
{
	if(!window.videoParamSeoName){
		if(!window.history.state)
		{
			if(pageName!='video')window.history.pushState({type: 'vodPlayer', seo_name: seoname}, '' ,window.playDeepLink);//do nothing for video page
		}
		else
		{
			if(!window.history.state.seo_name !== seoname)
			{
				window.history.replaceState({type: 'vodPlayer', seo_name: seoname}, '',window.playDeepLink);
			}
		}
		$(window).unbind('popstate.vod popstate.default load.default').bind('popstate.vod', function ()
		{
			if (this.history.state && this.history.state.type == 'vodPlayer')
			{
				_play(this.history.state.seo_name);
			}
		})
	}else{
		if (!window.history.state) {
			if(pageName!='video') window.history.pushState({type: 'vodPlayer', seo_name: seoname}, '', window.playDeepLink);
		}
	}
	window.videoPlayingType = playType;
	_play(seoname)

	function _play(_seoName)
	{
		gPlayer && gPlayer.initVODModal(_seoName);
	}
}

function checkChannelAccess(seoName, callback)
{
	NEU.util.getJSON(window.LOC_SERVER + 'channel/' + seoName + '?format=json', function (data)
	{
		waitFinalStatus(function () {
			if (!data.noAccess || isTemporaryUser || (isMVPDLoggedIn && userAuth && userAuth.length > 0 && isIncludeAnyResourceId(userAuth, getResourceId(data.id))))
			{
				callback && callback(true, data);
			}
			else
			{
				if (window.isTVE && userType == 'anonymous') {
					var isFree = getResourceId(data.id) == '' ? true : false;
					_FreeSample && _FreeSample.check(isFree, function () {
						data.noAccess = false;
						callback && callback(true, data);
					});
				} else {
					showAuthPopup(data.code);
					callback && callback(false);
				}
			}
		});
	})
}

function isIncludeAnyResourceId(userAuthArray, resourceIds)
{
	var isExist = false;
	var resourceIdArray = resourceIds.split(',');
	for (var i = 0; i < resourceIdArray.length; i++)
	{
		if (userAuthArray.includes('tve-auth-' + resourceIdArray[i]))
		{
			isExist = true;
			break;
		}
	}
	return isExist;
}

var hasChannelFrame = false;
function playChannel(seoname, _target)
{
	playDeepLink = setURLParam('seoName',seoname.toLowerCase());
	g_nlAdobePassRefreshURL = window.playDeepLink;
	if (_target)
	{
		waitFinalStatus(function(){
			var $parent = $(_target).parents('.program')
			var unlock = $parent.find('.js-channel-lock').is(':hidden');
			if (!unlock)
			{
				if (window.isTVE && userType == 'anonymous') {
					var isFree = !$parent.data('resourceid') ? true : false;
					_FreeSample && _FreeSample.check(isFree,function(){ play();});
				}else{
					showAuthPopup($parent.data('accesstype'), $parent.data('resourceid'))
				}
			}else{
				play()
			}
		});
	}else{
		play();
	}

	function play(){
		if(!window.channelParamSeoName){
			if(!window.history.state)
			{
				if(pageName!='channel')window.history.pushState({type: 'channelPlayer', seo_name: seoname},null, window.playDeepLink);
			}
			else
			{
				if(!window.history.state.seo_name !== seoname)
				{
					window.history.replaceState({type: 'channelPlayer', seo_name: seoname}, null,window.playDeepLink);
				}
			}
			$(window).unbind('popstate.channel popstate.default load.default').bind('popstate.channel', function ()
			{
				if (this.history.state && this.history.state.type == 'channelPlayer')
				{
					var _seoName = this.history.state.seo_name;
					_play(_seoName);
				}
			})
		} else {
			if (!window.history.state)
			{
				window.top.history.pushState({type: 'channelPlayer',seo_name: seoname	}, null, window.playDeepLink);
			}
		}
		_play(seoname);
		function _play(seo_name)
		{
			gPlayer && gPlayer.initChannelModal(seo_name);
		}
	}
}

function setChannelCustomDimension(channel)
{
	// 3, [Hit] 	Channel Location
	ga('set', 'dimension3', NEU.util.getCookie('chooseRegion') || contentRegions[0].name);
	// 4, [Hit] 	Network
	ga('set', 'dimension4', channel.name);
}
/* show player end*/

function listenUnsupported()
{
	var message = NEU.util.getLocalizedString("video_unsupported");
	var modalConfig = {
		desc: message,
		buttons: [
			{
				"title": NEU.util.getLocalizedString("ok"),
				"type": "yes"
			}
		]
	};
	NEU.ui.modal.show(modalConfig);
}
function processVideo(video)
{
	processPCM(video);
	processDRM(video);
}
function processPCM(video)
{
	if (video.publishPointParams == null)
	{
		video.publishPointParams = {};
	}
	if (window.nlCMConfig && window.pcid)
	{
		video.publishPointParams.pcid = window.pcid;
	}
}
function processDRM(video)
{
	if (video.drm)
	{
		video.isDRM = true;
		if (video.publishPointParams != null)
			video.publishPointParams.deviceid = "web-" + window.sid;
		else
			video.publishPointParams = {deviceid: "web-" + window.sid};
	}
}

function videoSessionTimeout()
{
	var pcmModalConfig = {
		type: "default",
		title: "",
		desc: NEU.util.getLocalizedString("video_session"),
		buttons: [
			{
				"title": NEU.util.getLocalizedString("continue"),
				"type": "yes",
				"callback": resumePlayer
			},
			{
				"title": NEU.util.getLocalizedString("cancel"),
				"type": "no"
			}
		]
	};
	NEU.ui.modal.show(pcmModalConfig);
}

function resumePlayer()
{
	// TODO right now page reload won't resume player
	parent.location.reload();
}
function programPositionChange(id)
{
	if (window.ps && window.enablePs)
	{
		getViewHistoryOfProgram("update", function (data)
		{
			if (id)
			{
				for (var i in data.list)
				{
					var program = data.list[i];
					if (program.id === id)
					{
						var item = $('body .program__progress[data-programid="' + program.id + '"]');
						if (item.length > 0)
						{
							item.width(program.dur).parent('.program__bar').show();
						}
						break
					}
				}
			}
			window.historyListCache = data.list = data.list.filter(function (item)
			{
				return item.position != 0 || item.completed == 0;
			});
			$(document).trigger('programPositionChange', data);
		})
	}
}

//P.C. tvRating
function processDefaultTVRating(tvRating)
{
	tvRating = tvRating || '';
	if (window.TV_RATING_MAP[tvRating])
	{
		return window.TV_RATING_MAP[tvRating];
	}
	else
	{
		return tvRating;
	}
}

/*Project Custom for Parental Controls*/
window.pinInputCompleteCallback = function (result) {
	ga && ga('send', 'event', { eventCategory: 'Engagement', eventAction: 'Entered PIN', eventLabel: result });
};

function programNotAuthorizedHandle(errorDetail, needExitPlayer) {
	var message = '';
	var morelink = '';
	var authorizationErrorType = window.RESOURCEID_MRSS_MAP[mvpdProvider] ? window.RESOURCEID_MRSS_MAP[mvpdProvider]['authorizationErrorType'] : window.RESOURCEID_MRSS_MAP['default']['authorizationErrorType'];

	if (authorizationErrorType == '1') {
		if (errorDetail && errorDetail.split('https')) {
			message = errorDetail.split('https')[0];
			morelink = 'https' + errorDetail.split('https')[1];
		}
	} else if (authorizationErrorType == '2') {
		message = errorDetail;
	}

	$('.js-program-notauthorized .js-close').attr('href', 'javascript:confirmNotAuthorized(' + needExitPlayer + ')');
	$('.js-program-notauthorized .js-alert-message').html(message);
	if (morelink){
		$('.js-program-notauthorized a.alert__button-more').attr('href', morelink).show();
	}else{
		$('.js-program-notauthorized a.alert__button-more').hide();
	}
	showOverlay('.js-program-notauthorized');
}

function confirmNotAuthorized(autoSwitch)
{
	// var playerFrame = '';
	if(hasChannelFrame && !autoSwitch){
		window.playPreAuthEpg();
	}else{
		$('#common-player .back').click();
	}
}

function getURLParam(param,sourceURL) {
	if (!sourceURL) sourceURL = window.location.href;
	var query = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
	var queryParams = query.split("&");
	for (var i = 0; i < queryParams.length; i++) {
		var queryParam = queryParams[i].split("=");
		if (queryParam.length > 1 && queryParam[0] === param) {
			return decodeURIComponent(queryParam[1]);
		}
	}
	return false;
}


function setURLParam(key, value, url)
{
	if (!url) url = window.location.href;
	var key = encodeURIComponent(key),
			value = encodeURIComponent(value);

	var baseUrl = url.split('?')[0],
			newParam = key + '=' + value,
			params = '?' + newParam;

	if (url.split('?')[1] === undefined){ // if there are no query strings, make urlQueryString empty
		urlQueryString = '';
	} else {
		urlQueryString = '?' + url.split('?')[1];
	}

	// If the "search" string exists, then build params from it
	if (urlQueryString) {
		var updateRegex = new RegExp('([\?&])' + key + '=[^&]*');
		var removeRegex = new RegExp('([\?&])' + key + '=[^&;]+[&;]?');

		if (value === undefined || value === null || value === '') { // Remove param if value is empty
			params = urlQueryString.replace(removeRegex, "$1");
			params = params.replace(/[&;]$/, "");

		} else if (urlQueryString.match(updateRegex) !== null) { // If param exists already, update it
			params = urlQueryString.replace(updateRegex, "$1" + newParam);

		} else if (urlQueryString == '') { // If there are no query strings
			params = '?' + newParam;
		} else { // Otherwise, add it to end of query string
			params = urlQueryString + '&' + newParam;
		}
	}

	// no parameter was set so we don't need the question mark
	params = params === '?' ? '' : params;

	return baseUrl + params;
}


function removeURLParam(key, sourceURL)
{
	if (!sourceURL) sourceURL = window.location.href;
	var rtn = sourceURL.split("?")[0],
			param,
			params_arr = [],
			queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
	if (queryString !== "")
	{
		params_arr = queryString.split("&").filter(function (item) {
			return item != ""
		});
		for (var i = params_arr.length - 1; i >= 0; i -= 1)
		{
			param = params_arr[i].split("=")[0];
			if (param === key)
			{
				params_arr.splice(i, 1);
			}
		}
		if (params_arr.length > 0)
		{
			rtn = rtn + "?" + params_arr.join("&");
		}

	}
	return rtn;
}